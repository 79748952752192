<template>
  <div>
    <div v-if="!isIndexPage" class="flex">
      <side-bar
        :show-app-links="!!organisationId && isOnboardingComplete"
        :show-settings="isOnboardingComplete"
      />

      <div class="w-full grow overflow-auto pb-20 lg:ml-[270px]">
        <Modals />
        <div class="mx-auto max-w-[120rem]">
          <ModulesCreditFacilityErrorBanner v-if="creditFacilityIsDisabled" />
          <template v-if="isOnboardingComplete">
            <nav-bar
              class="px-4 md:px-0"
              :show-actions="hasOnboardingAcknowledged"
            />
          </template>
          <slot />
        </div>
      </div>
    </div>
    <!-- fixed -->
    <div v-else>
      <div class="relative h-screen w-full">
        <slot />
      </div>
    </div>
    <AuthAutoLogout />
  </div>
</template>

<script setup lang="ts">
import NavBar from '@/components/shared/nav-bar.vue'
import SideBar from '@/components/shared/side-bar/side-bar.vue'
import Modals from '~/components/shared/modals/the-modals.vue'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'
import { useInspectlet } from '~/composables/useInspectlet'
import CONSTANTS from '~/constants/CONSTANTS'
// import { getLastItemInArray } from '~/lib/utils'

const { $lenkieApi, $api, $listen } = useNuxtApp()
const route = useRoute()
const auth = useAuthStore()
const { isLoggedIn, personId } = storeToRefs(auth)
const profileStore = useProfileStore()
const connectionStore = useConnectionsStore()
const isIndexPage = computed(() => route.path === routes.home)

const creditFacilityStore = useCreditFacilityStore()
const featureFlagStore = useFeatureFlagStore()
const { hasOnboardingAcknowledged } = storeToRefs(featureFlagStore)
const { creditFacilityIsDisabled } = storeToRefs(creditFacilityStore)
const { organisationId, isGNPLUser, isOnboardingComplete } =
  storeToRefs(profileStore)
const { hasActiveAccountingPlatformConnection } = storeToRefs(connectionStore)

const fetchProduct = async () => {
  try {
    let requestUri = `/Products/organisation?PersonId=${personId.value}`

    if (organisationId) {
      requestUri += `&organisationId=${organisationId.value}`
    }

    const { status, data } = await $lenkieApi.get(requestUri)

    if (status === 200) {
      if (data.result.length > 0) {
        if (data.result.length === 1) {
          if (data.result[0].code === 'CARDPAY') {
            profileStore.setIsGNPLUser(false)
          }
          if (data.result[0].code === 'GNPL') {
            profileStore.setIsGNPLUser(true)
          }
        }
        if (data.result.length > 1) {
          profileStore.setIsGNPLUser(true)
        }
      }
    }
  } catch (error) {}
}

watch(
  organisationId,
  async (newVal) => {
    if (newVal) {
      await connectionStore.getConnectedAccountingPlatforms()

      if (connectionStore.connectedAccountingPlatforms.count === 1) {
        await connectionStore.getExpenseAccountsByOrgId(newVal)

        if (connectionStore.expenseCategories.length > 0) {
          await connectionStore.getTaxes(newVal)
        }
      }
    }
  },
  { immediate: true },
)

watch(
  () => [isLoggedIn.value, organisationId.value] as const,
  (newVal) => {
    const [loggedIn, orgId] = newVal
    if (loggedIn && !!orgId) {
      ;(async () => {
        await fetchProduct()
      })()
    }
  },
  { immediate: true },
)

async function fetchBorrowerCreditFacility(organisationId: string) {
  try {
    const { data } =
      await $api.banking.creditFacilities.getBorrowerCreditFacility(
        organisationId,
      )
    creditFacilityStore.$patch({
      creditFacilityDetails: data,
    })
  } catch (error) {}
}

async function fetchCreditFacilityFundingDetails(organisationId: string) {
  try {
    const { data } =
      await $api.banking.creditFacilities.getCreditFacilityFundRequest(
        organisationId,
      )
    creditFacilityStore.$patch({
      creditFacilityRequestFundingDetails: data,
    })
  } catch (error) {}
}

watch([isGNPLUser, organisationId], (newVal) => {
  const [isGnpl, orgId] = newVal
  if (orgId) {
    fetchCreditFacilityFundingDetails(orgId)
    if (isGnpl) {
      fetchBorrowerCreditFacility(orgId)
    }
  }
})

$listen('update:credit-facility', () => {
  if (organisationId.value) {
    fetchCreditFacilityFundingDetails(organisationId.value)
    fetchBorrowerCreditFacility(organisationId.value)
  }
})

function forceSyncAccountingConnection() {
  if (
    !isLoggedIn.value ||
    !organisationId.value ||
    !hasActiveAccountingPlatformConnection.value
  ) {
    return
  }
  $api.banking.bills.forceSyncAccountingConnection(organisationId.value)
}

onMounted(() => {
  useInspectlet()
  setInterval(() => {
    forceSyncAccountingConnection()
  }, CONSTANTS.ACCOUNTING_SYNC_INTERVAL_TIME)
})
</script>

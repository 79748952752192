import { watchImmediate } from '@vueuse/core'
import insp, { install } from 'inspectlet-es'
import { useAuthStore } from '~/stores/auth'

export function useInspectlet() {
  const { personId } = storeToRefs(useAuthStore())
  const config = useRuntimeConfig()
  if (config.public.APP_ENV !== 'production') return
  const inspectletId: number = 643257092
  install(inspectletId)

  const { profile, currentlySelectedOrganisation } =
    storeToRefs(useProfileStore())

  watchImmediate(
    [personId, profile] as const,
    (newVal) => {
      if (!newVal[0]) return
      if (!newVal[1]) return

      const [_personId, _profile] = newVal
      const { work_email_address, family_name, given_name } = _profile
      insp(['identify', work_email_address])
      insp([
        'tagSession',
        {
          email: work_email_address,
          userName: `${given_name} ${family_name}`,
          personId: _personId,
          orgId: currentlySelectedOrganisation.value?.id || null,
        },
      ])
    },
    { deep: true },
  )
}
